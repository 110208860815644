.nftHeading {
  font-family: "Seymour One", sans-serif;
  background: -webkit-linear-gradient(#d32c42, #f78245);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: xxx-large; */
  font-size: 3.5rem;
  font-weight: 900;
}
.stakeBTNS {
  background: #021335;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  transition: 900ms;
}
.stakeBTNSActive {
  background: #144dbd;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  transition: 900ms;
}
.stakeBTNS:hover {
  transform: scale(1.1);
  transition: 900ms;
}
.stake {
  background: #021335;
  border-radius: 20px;
  height: 60px;
  width: 150px;
  transition: 900ms;
}
.stake:hover {
  transform: scale(1.1);
  transition: 900ms;
}

.tenure {
  font-family: "Montserrat", sans-serif;
  background: -webkit-linear-gradient(#d32c42, #f78245);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: xxx-large; */
  font-size: 1.5rem;
  font-weight: bolder;
}
.tenureStake {
  font-family: "Montserrat", sans-serif;
  background: -webkit-linear-gradient(#d32c42, #f78245);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: xxx-large; */
  font-size: 1rem;
  font-weight: bolder;
}
.containerStaked {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  border-radius: 0.5rem;
  height: 15rem;
  width: 100%;
  overflow: hidden;

  position: relative;

  padding: 1rem;
  overflow-y: scroll;
}
.containerStaked::-webkit-scrollbar {
  width: 0.5rem;
}
.containerStaked::-webkit-scrollbar-thumb {
  background-color: rgb(163, 154, 154);
  border-radius: 12px;
  display: none;
}
.stakingCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  width: 600px;
}
.innerDiv {
  display: flex;
  justify-content: start;
  align-items: start;
}
.stakingCardImg {
  text-align: center;
  height: 100%;
  /* width: 30rem; */
  cursor: pointer;
}
.stakingCardImg img {
  width: 90px;
  height: 90px;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
input.CheckBox {
  background-color: transparent !important;
  border: 1px solid rgb(163, 154, 154);

  cursor: pointer;
  color: #d32c42;
  margin-top: 2rem;
}
.form-check-input {
  border-radius: 0.2rem;
  height: 1.3rem;
  width: 1.3rem;
}
.CheckBox:hover {
  background-color: #d32c42;
  color: white;
  transition: 0.3s;
}
.CheckBox:checked {
  background-color: #d32c42;
  border-color: #d32c42;
}
@media screen and (max-width: 1030px) {
  .linesNFT {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .nftHeading {
    font-family: "Seymour One", sans-serif;
    background: -webkit-linear-gradient(#d32c42, #f78245);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-size: xxx-large; */
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
  }
  .stakingCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    width: 100%;
  }
}
