.headSubscribe {
  color: white;
  text-shadow: 1px 0 white;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: xx-large;
  width: 100%;
}
.subscribeDiv {
  border-left: 2px solid #c4cbd4;
  margin-left: 2rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-right: 2rem;
}
.subscribeContainer {
  border: 2px solid transparent;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.input-1 {
  border: 2px solid transparent;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  outline-style: none;
  margin-left: 1rem;
}
.input-2 {
  border: 2px solid transparent;
  outline-style: none;
}
.subscribeContainer-hidden {
  display: none;
}
.subscribeBTN {
  margin-left: 16rem;
  height: 75px;
  border: 4px solid #0a1f50;
  border-left-color: #0a1f50;
  margin-top: -0.2rem;
  width: 200px;
  border-radius: 35px;
  font-weight: 600;
  padding-left: 3rem;
  padding-right: 5rem;
}

@media screen and (max-width: 1092px) {
  .subscribeContainer {
    display: none;
  }
  .subscribeContainer-hidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .input-1-hidden {
    border: 2px solid transparent;
    border-radius: 35px;
    outline-style: none;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    height: 50px;
    padding-right: 1rem;
  }
  .input-2-hidden {
    border: 2px solid transparent;
    border-radius: 35px;
    height: 50px;
    outline-style: none;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .subscribeBTN-hidden {
    border: 2px solid transparent;
    border-radius: 35px;
    outline-style: none;
    height: 50px;

    width: 140px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
