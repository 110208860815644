@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap");
body {
  background-color: #030e2b;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  font-family: Poppins, sans-serif;
  font-size: 16px;
}
h1 {
  font-size: 2rem;
}
@media (min-width: 992px) {
  h1 {
    font-size: 3rem;
  }
}
h2 {
  font-size: 2rem;
  font-weight: 900;
}
@media (min-width: 992px) {
  h2 {
    font-size: 3.375rem;
    font-weight: 900;
  }
}
.btn-grad-bg {
  background: rgb(44, 23, 111);
  background: -moz-linear-gradient(
    90deg,
    hwb(254 9% 56%) 40%,
    rgba(69, 43, 152, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(44, 23, 111, 1) 40%,
    rgba(69, 43, 152, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(44, 23, 111, 1) 40%,
    rgba(69, 43, 152, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2c176f", endColorstr="#452b98", GradientType=1);
}
.web3modal-modal-lightbox {
  z-index: 50 !important;
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  right: 0;
  /* margin: 0 auto; */
  top: 0;
  /* bottom: 0; */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #0c3050; /* Fallback color */
  background-color: #0c3050b4; /* Black w/ opacity */
}
.modal_inner {
  margin: 0 auto; /* 15% from the top and centered */
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 20px;
  border: 1px solid #888;
  width: 65%; /* Could be more or less, depending on screen size */
  height: 60%;
  border-radius: 10px;
  overflow: auto;
  background-color: #fff;
  padding-top: 10px;
}
.modal_inner::-webkit-scrollbar {
  background-color: #888;
}
.modal_inner::-webkit-scrollbar-thumb {
  background-color: #6b7593;
  border-radius: 10px;
}
.modal_inner h1 {
  color: #6b7593;
  font-weight: 900;
  text-align: center;
  font-size: 1.5rem;
}
.modal_inner h2 {
  color: #6b7593;
  font-weight: 900;
  text-align: center;
  font-size: 1.2rem;
  text-align: left !important;
}
.modal_inner p {
  color: rgb(105, 133, 207);
  font-weight: 500;
}
.icon_term {
  color: #6b7593;
}

.hr_line {
  display: block;
  background-color: #6b7593;
}
.btn_accept {
  background-color: #0c3050;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  display: block;
  margin-top: 20px;
}

.btn_decline {
  background-color: white;
  color: #030e2b;
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid #030e2b;
  cursor: pointer;
  display: block;
  margin-top: 20px;
}
/* beautifull scrollbar */

@media screen and (max-width: 984px) {
  .modal_inner {
    width: 90%;
    height: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .btn-grad1::after {
    transform: translate(calc(100vw - 100% - 300px), 0) scale(1);
  }
}
@media screen and (max-width: 600px) {
  .hr_line {
    display: none;
  }
}
@media (min-width: 1240px) {
  .btn-grad1::after {
    transform: translate(calc(100vw - 200%), 0) scale(1);
  }
}

@media (min-width: 1440px) {
  .btn-grad1::after {
    transform: translate(calc(100vw - 200%), 0) scale(1);
  }
}

@media (min-width: 1690px) {
  .btn-grad1::after {
    transform: translate(calc(100vw - 200% - 300px), 0) scale(1);
  }
}

:root {
  --border-color: #ff4323;
  --font-color: #f2f3f5;
  --main-background: #0e0822;
  --cards-bg: #1b2e46;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
