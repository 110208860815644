.footer {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer_container {
  width: 1330px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  flex-direction: row;
  gap: 6rem;
  padding: 20px 0;
  margin-top: 5rem;
}
.logo {
  height: 5rem;
}
.logo img {
  width: 100%;
  height: 100%;
}
.list_one {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.social_links {
  display: flex;
  justify-content: start;
  align-items: start;
  color: white;
  gap: 12px;
}
.social_media p {
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
}

.link {
  display: block;
  transition: 0.3s;
}
.link:hover {
  padding-top: 5px;
  transition: 0.3s;
}
.btnFooter {
  background: linear-gradient(90deg, #f98747, #cf2442);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 50px;
  text-transform: uppercase;
  width: 17rem;
  word-spacing: 2px;
  letter-spacing: 0.5px;
}
.list_two {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}
.list_two ul {
  list-style: none;
  color: white;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
}
.list_two li:hover {
  text-decoration: underline;
}
.list_two h1 {
  font-size: 2.3rem;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
}
.list_three {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list_three h1 {
  font-size: 2.3rem;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
}
.list_four {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}
.list_four h1 {
  font-size: 2.3rem;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
}
.list_three ul {
  list-style: none;
  color: white;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
}
.list_three li:hover {
  text-decoration: underline;
}
.list_four ul {
  list-style: none;
  color: white;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;
}
.list_four li:hover {
  text-decoration: underline;
}
.copy_right {
  color: #444b5d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  font-size: 0.7rem;
  text-align: center;
}
.copy_rightContianer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1230px;
  margin-top: 3rem;
}

.bottom_footer {
  width: 1230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  justify-items: center;
  flex-direction: row;
  padding-left: 5rem;
  /* padding-right: 2rem; */
}

.bottom_footer_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  gap: 2rem;
}

.dash {
  content: "";
  width: 14px;
  height: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-self: center;
  background-color: #444b5d;
}
.bottom_footer_links {
  padding-top: 5px;
  color: #444b5d;
  cursor: pointer;
}
.bottom_footer_links:hover {
  text-decoration: underline;
  color: white;
}

.copy_right_heading {
  font-size: 1rem;
  font-weight: 500;
  color: #444b5d;
  /* margin-bottom: 20px; */
}
.bottom_container {
  width: 1330px;
}

@media screen and (max-width: 1330px) {
  .copy_rightContianer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-top: 3rem;
  }
  .footer_container {
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    flex-direction: row;
    gap: 6rem;
    padding: 20px 0;
    margin-top: 5rem;
  }
  .bottom_container {
    width: 95%;
  }
  .bottom_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 20px 0;
  }
}

@media screen and (max-width: 1160px) {
  .footer_container {
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6rem;
    padding: 20px 0;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 800px) {
  .bottom_footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px 0;
  }
}
@media screen and (max-width: 500px) {
  .footer_container {
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 0;
    margin-top: 0;
  }
  .copy_rightContianer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 3rem;
  }
  .bottom_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px 0;
  }
  .copy_right_heading {
    text-align: center;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .footer_container {
    width: 95%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 0;
    margin-top: 0;
  }
  .bottom_footer_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
  }
  .dash {
    display: none;
  }
  .copy_rightContianer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 3rem;
  }
  .bottom_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px 0;
  }
  .copy_right_heading {
    text-align: center;
    margin-top: 1rem;
  }
}
