.bg-navy {
  background: rgb(7, 70, 133);
  background: linear-gradient(
    180deg,
    rgba(7, 70, 133, 1) 34%,
    rgba(25, 115, 171, 1) 80%
  );
  margin-top: 0.4rem;
}
.btnNavy {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: rgb(7, 70, 133);
  background: linear-gradient(
    180deg,
    rgba(7, 70, 133, 1) 34%,
    rgba(25, 115, 171, 1) 80%
  );
}
.bot {
  margin-top: 0.4rem;
  border-bottom: 0.2rem;
  border-bottom-style: solid;
  border-bottom-color: white;
}
@media screen and (max-width: 932px) {
  .navContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
