.papa_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dao_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 1330px;
  margin-top: 10em;
}
.dao_container h1 {
  color: #f6ac03;
  font-size: 2.3em;
}
.dao_left {
  width: 45%;
  height: 42rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #001841;
  border-radius: 20px;
  padding: 2em;
}

.dao_right {
  width: 45%;
  height: 42rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #001841;
  border-radius: 20px;
  padding: 2em;
}
.box_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1.3rem;
  padding: 2rem;
}
.box_container p {
  color: white;
  font-size: 1.1em;
  margin: 0;
}

@media screen and (max-width: 1330px) {
  .dao_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10em;
  }
  .dao_left {
    width: 100%;
    /* height: 42rem; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #001841;
    border-radius: 20px;
    padding: 1em;
  }
  .dao_right {
    width: 100%;
    /* height: 42rem; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #001841;
    border-radius: 20px;
    padding: 1em;
  }
  .box_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 1.3rem;
    padding: 2rem;
  }
}
@media screen and (max-width: 430px) {
  .dao_container h1 {
    color: #f6ac03;
    font-size: 1.8em;
    text-align: center;
  }
}
