.h1-main {
  font-family: "Seymour One", sans-serif;
  background: -webkit-linear-gradient(#d32c42, #f78245);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: xxx-large; */
  font-size: 3rem;
}
.gradiantBTN {
  border: 3px solid;
  border-image: linear-gradient(45deg, #d02642, #f78245) 1;
  transition: 900ms;
}
.gradiantBTN:hover {
  transition: 900ms;
  transform: scale(1.1);
}
.gradiantBTN-2 {
  border: 3px solid;
  border-image: linear-gradient(45deg, #2667d0, #79e5f4) 1;
  transition: 900ms;
}
.gradiantBTN-2:hover {
  transition: 900ms;
  transform: scale(1.1);
}
.gradiantBTN-3 {
  border: 3px solid;
  border-image: white;
  transition: 900ms;
}
.gradiantBTN-3:hover {
  transition: 900ms;
  transform: scale(1.1);
}
.left-container {
  background-color: transparent;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1150px) {
  .left-container {
    width: 600px;
  }
}
@media screen and (max-width: 630px) {
  .left-container {
    width: 400px;
  }
  .h1-main {
    font-family: "Seymour One", sans-serif;
    background: -webkit-linear-gradient(#d32c42, #f78245);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-size: xxx-large; */
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  .left-container {
    width: 300px;
  }
  .h1-main {
    font-family: "Seymour One", sans-serif;
    background: -webkit-linear-gradient(#d32c42, #f78245);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-size: xxx-large; */
    font-size: 2.2rem;
  }
}
