:root {
  /* --primary: #000; */
  --primary-text: #fff;
  --secondary: #555;
  --secondary-text: #ffffff;
  --accent: rgba(20, 20, 20, 0.5);
  --accent-text: #ffffff;
  --text: "coder";
}
@font-face {
  font-family: "coder";
  src: local("coder"), url(font.woff) format("woff");
}

.styleBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.boxShadow {
  box-shadow: 1px 3px 15px 2px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 1px 3px 15px 2px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 1px 3px 15px 2px rgba(255, 255, 255, 0.75);
}
