.banner-top {
  background: #fe4d87;
  background: linear-gradient(
    90deg,
    #fe4d87,
    #f74061 34%,
    #da22bc 61%,
    #9e29ff
  );
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  padding-left: 8rem;
  padding-right: 6rem;
  gap: 1rem;
  /* z-index: 99; */
}

.banner-top .btn-white {
  background-color: #fff;
  font-size: 0.75rem;
  padding: 0.2rem 1rem;
  cursor: pointer;
  border-radius: 1rem;
  margin-right: 2rem;
  color: #000;
  text-transform: uppercase;
}
.banner-top p {
  margin-bottom: 2px;
}
/* @media (min-width: 1200px) {
  .banner-top .opacity-0 {
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 992px) {
  .banner-top {
    padding: 0.5rem 5rem;
  }
}
@media (min-width: 1400px) {
  .banner-top {
    padding-left: 14rem;
  }
}
@media (min-width: 1200px) {
  .banner-top {
    padding-left: 8rem;
  }
} */
.banner-top > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  gap: 0.5rem;
  color: #fff;
}
.banner-top > div p {
  margin-bottom: 4px;
}
/* @media (min-width: 992px) {
  .banner-top > div {
    -ms-flex-pack: center;
    justify-content: center;
  }
} */
.banner-top .btn-white {
  background-color: #fff;
  font-size: 0.75rem;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  margin-right: 2rem;
  color: #000;
  text-transform: uppercase;
}
.btn-gradient {
  background: #f98747;
  color: #fff;
  background: linear-gradient(90deg, #f98747, #cf2442);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  text-transform: none;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .btn-gradient {
    font-size: 1rem;
  }
}
.btn-gradient .icon {
  margin-right: 0.3rem;
}
@media (min-width: 992px) {
  .banner-top .btn-white {
    font-size: 0.9rem;
  }
}
.navbar-toggler {
  position: relative;
  z-index: 4;
}
.navbar {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  /* width: 100vw; */
  height: 75px;
  width: 100%;
  padding-left: 8rem;
  padding-top: 1rem;
  z-index: 2;
}
.navbar img {
  max-width: 250px;
}
.cross {
  display: none;
}
@media screen and (max-width: 1500px) {
  .navbar {
    padding-left: 2rem;
  }
}
/* @media (min-width: 1200px) {
  .navbar {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1400px) {
  .navbar {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (min-width: 1600px) {
  .navbar {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
.navbar-brand {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.navbar img {
  top: 10px;
  max-width: 248px;
}
@media (min-width: 768px) {
  .navbar img {
    max-width: 264px;
  }
}
.navbar-nav {
  gap: 1rem;
  padding: 2rem;
  -ms-flex-align: baseline;
  align-items: baseline;
}
@media (min-width: 992px) {
  .navbar-nav {
    padding: unset;
  }
}
.navbar-collapse {
  position: absolute;
  width: 300px;
  z-index: 3;
  left: 0;
  top: 0;
  min-height: 100vh;
  padding-top: 2rem;
  background-color: #1f1f1f;
  transform: translateX(-100%);
}
.navbar-collapse .btn-gradient {
  background: #f98747;
  color: #fff;
  background: linear-gradient(90deg, #f98747, #cf2442);
  max-height: 30px;

  display: flex;

  justify-content: center;

  align-items: center;
  border-radius: 2rem !important;
}
.navbar-collapse .btn-gradient a > span:first-of-type {
  margin-right: 0.5rem;
}
.navbar-collapse .btn-gradient .nav-link {
  padding: 0.1rem 0.2rem;
}
.navbar-collapse .nav-link {
  color: #fff !important;
  text-transform: uppercase;
  font-size: 0.9rem;
}
.navbar-collapse.show {
  transform: translateX(0);
}
@media (min-width: 992px) {
  .navbar-collapse {
    position: relative;
    width: 100%;
    left: auto;
    top: auto;
    min-height: auto;
    padding-top: 0;
    background-color: transparent;
    transform: translateX(0);
  }
} */
#menuBarPriceLbl {
  color: #f9b41b;
  font-size: 12px;
  font-family: inherit;
  position: absolute;
  top: -10px;
  left: 12.3rem;
}

#menuBarPriceLbl:before {
  content: "⬢";
  font-size: 9px;
}
#menuBarPriceLbl:before {
  transform: rotate(30deg);
}
#menuBarPriceLbl:before {
  animation-name: a;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.navbar-nav {
  gap: 1rem;
  padding: 2rem;
  -ms-flex-align: baseline;
  align-items: baseline;
}
@media (min-width: 992px) {
  .navbar-nav {
    padding: unset;
  }
}
.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-menu.show .dropdown-item {
  color: #fff;
}
.dropdown-menu.show .dropdown-item:hover {
  background-color: #d12942;
}
.nice-select .list {
  background-color: #001841;
}
.nice-select .option.focus,
.nice-select .option.selected.focus,
.nice-select .option:hover {
  background-color: #d12942;
}
.navbar-toggler {
  position: relative;
  z-index: 4;
}
.spanAngle {
  width: 2rem;
  height: 2rem;
}
.navBar {
  /* width: 100vw; */
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center; */
  /* padding: 2rem; */
}

.btnbtn {
  background: #f98747;
  color: #fff;
  background: linear-gradient(90deg, #f98747, #cf2442);
  max-height: 30px;
  display: flex;
  width: 180px;
  justify-content: space-between;
  border: none;
  padding: 0.3rem 1rem;
  align-items: center;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 0.9rem;
  border-radius: 2rem !important;
  outline: none;
  position: relative;
}
.nav-links {
  text-transform: uppercase;
  color: white;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
}

.nav-item {
  padding: 0 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}
.innerLi {
  position: absolute;
  background-color: #001841;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 175px;
  height: 120px;
  margin-top: 5px;
}

.innerUl {
  list-style: none;
  margin-left: -15px;
  width: 100%;
  line-height: 10px;
  padding-bottom: 5px;
}
.innerli {
  width: 100%;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 5px;
}
.innerli:hover {
  background-color: #d12942;
}
.innerLi2 {
  position: absolute;
  background-color: #001841;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 5px;
  width: 155px;
  height: 400px;
  margin-top: 5px;
  margin-left: -5px;
}
.innerli2 {
  text-transform: none;
  font-size: 1rem;
  padding-left: 10px;
  cursor: pointer;
  line-height: 1.6rem;
}
.innerli2:hover {
  width: 100%;
  background-color: #d12942;
}
.mobile_header {
  display: none;
}

@media screen and (max-width: 1400px) {
  .navbar {
    padding-left: 3rem !important;
  }
}
@media screen and (max-width: 1236px) {
  .mobile_header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    width: 100%;
  }
  .nav-links {
    display: none;
  }
  .menu_class {
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 2rem;
    width: 20rem;
    background-color: #1f1f1f;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000000;
  }

  .menu_class ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2rem;
    width: 100%;
    padding: 0;
    color: white;
    list-style: none;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
  }
  .mobileBTN {
    background: #f98747;
    color: #fff;
    background: linear-gradient(90deg, #f98747, #cf2442);
    border: none;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    text-transform: none;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }
  .innerLi_mobile {
    background-color: #001841;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 175px;
    height: 140px;
    margin-top: 5px;
    list-style: none;
  }
  .innerLi2_mobile {
    background-color: #001841;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0.5rem;
    margin-top: 5px;
    list-style: none;
    border-radius: 0.5rem;
  }
  .innerli_mobile {
    width: 100%;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 5px;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: white;
  }
  .innerli_mobile:hover {
    background-color: #d12942;
  }
}
.headerLogo {
  position: relative;
}

/* create beautifull animation for div when it renders */
@media screen and (max-width: 768px) {
  .navbar img {
    max-width: 180px;
  }
  #menuBarPriceLbl {
    color: #f9b41b;
    font-size: 12px;
    font-family: inherit;
    position: absolute;
    top: -10px;
    left: 8.3rem;
  }
  .navbar {
    padding: 0.5rem 1rem;
  }
  .banner-top {
    padding: 0.5rem 1rem;
  }
}
@media screen and (max-width: 350px) {
  .cross {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: white;
  }
}
