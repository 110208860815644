.stakingMain {
  background-image: url(../../assets/Staking-HeroBG.png);
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main_staking {
  width: 1330px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.staking {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: row;
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
}
.staking_left {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
}
.staking_left p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  /* width: 88%; */
  margin-bottom: 20px;
}
.staking_left h1 {
  font-size: 4rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 20px;
}
.staking_right {
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
  flex-direction: column;
  background-color: #001841;
  border-radius: 20px;
  padding: 20px;
  width: 65rem;
}
.staking_right_top {
  height: 6rem;
}
.staking_right_top img {
  width: 100%;
  height: 100%;
}
.staking_right h1 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  width: 80%;
}
.staking_heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.staking_right p {
  font-size: 1.1rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}
.inner_left {
  display: flex;
  gap: 20px;
}
.inner_left span {
  height: 7rem;
  width: 8rem;
}
.inner_left span img {
  width: 100%;
  height: 100%;
}
.btn_staking_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 14rem;
  height: 3rem;
  background-color: #fff;
  color: black;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid white;
  text-transform: uppercase;
}
.btn_staking_main:hover {
  box-shadow: 0px 0px 30px 0px #fff;
}

@media screen and (max-width: 1330px) {
  .main_staking {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1260px) {
  .staking {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    gap: 2rem;
  }
  .staking_right {
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;
    flex-direction: column;
    background-color: #001841;
    border-radius: 20px;
    padding: 20px;
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .staking {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    gap: 2rem;
  }
  .staking_left p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;
    /* width: 88%; */
    margin-bottom: 20px;
  }
  .staking_left h1 {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
  }
  .inner_left {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .inner_left span {
    height: 5rem;
    width: 5rem;
  }
  .inner_left span img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 468px) {
  .staking_right {
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;
    flex-direction: column;
    background-color: #001841;
    border-radius: 20px;
    padding: 10px;
    width: 100%;
  }
}
