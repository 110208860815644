.btn-gradient {
  background: #f98747;
  color: #fff;
  background: linear-gradient(90deg, #f98747, #cf2442);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  text-transform: none;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.txt {
  color: linear-gradient(90deg, #f98747, #cf2442);
}
