.heading {
  font-family: "Montserrat", sans-serif;
  background: -webkit-linear-gradient(#d32c42, #f78245);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: xxx-large; */
  font-size: 1.5rem;
}
.swpTEXT {
  font-family: "Montserrat", sans-serif;
  background: -webkit-linear-gradient(#d32c42, #f78245);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.containedCards {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  background: rgb(1, 18, 49);
  background: linear-gradient(
    221deg,
    rgba(1, 18, 49, 1) 34%,
    rgba(0, 26, 71, 1) 80%
  );
}

@media screen and (max-width: 900px) {
  .megaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .containedCards {
    width: 98%;
  }
}
.hr {
  box-shadow: 0 4px 6px -6px darkGray;
}
