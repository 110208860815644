.loginCard {
  background-color: #001334;
  border-radius: 20px;
  border: 1px solid #011a4f;
  width: 400px;
  /* height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.loginCard h1 {
  color: #b3ae9d;
  font-size: 0.8rem;
  font-weight: 500;
}

.loginCard span {
  color: #ff7b40;
  font-size: 2rem;
  font-weight: 500;
}
.top_card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #001232;
}
.inputDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.inputDiv label {
  color: white;
  font-size: 1rem;
  font-weight: 500;
}
.inputDiv input {
  border: 1px solid #ff7b40;
  padding: 10px;
  outline: none;
  background-color: #001232;
  color: #b3ae9d;
  width: 100%;
}
.inputDiv select {
  border: 1px solid #ff7b40;
  padding: 10px;
  outline: none;
  background-color: #001232;
  color: #b3ae9d;
  width: 100%;
  cursor: pointer;
}

form {
  width: 80%;
}
.registerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.registerDiv button {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 30px;
  height: 3.3rem;
  width: 10rem;
  color: black;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
}
.anu {
  display: flex;

  background-color: #001841;
  border-radius: 20px;
  border: none;
  outline: none;
  /* width: 30%; */
  /* width: 400px; */
  /* height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.pricing_Card {
  display: flex;

  background-color: #001841;
  border-radius: 20px;
  border: none;
  outline: none;
  /* width: 30%; */
  /* width: 400px; */
  /* height: 300px; */
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin: 0 auto;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.anu:hover {
  border: 1px solid #ff7b40;
}
.pricing_Card h1 {
  color: #ff7b40;
  font-size: 1.3rem;
  font-weight: 500;
}
.pricing_Card span {
  color: white;
  font-size: 2rem;
  font-weight: 500;
}
.pricing_Card span span {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .pricing_Card {
    width: 95%;
  }
  .loginCard {
    width: 95%;
  }
  .registerDiv button {
    background-color: white;
    border: none;
    padding: 10px;
    border-radius: 30px;
    height: 3.3rem;
    width: 9rem;
    color: black;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    margin-top: 10px;
  }
}
.pricing_Card_Body {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;
}
.pricing_Card_Body ul {
  list-style: none;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;
}
.pricing_Card_Body li {
  display: flex;
  gap: 5px;
  color: white;
  word-break: break-all;
  justify-content: start;
  align-items: start;
}
.registerDiv button:hover {
  box-shadow: 0px 0px 20px 0px white;
}
