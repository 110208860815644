.box_li {
  width: 300px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  background-color: #030e2b;
  border-radius: 22px;
  cursor: pointer;
}
.box_li:hover {
  background-color: #061431;
}

@media screen and (max-width: 768px) {
  .box_li {
    width: 100%;
    margin: 1rem;
    gap: 1rem;
  }
}
