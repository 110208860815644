.hero-section {
  background-image: url(../../assets/bg.png);
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main_container {
  width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  /* padding-left: 1rem; */
  gap: 3rem;
  padding-right: 3rem;
}
.main_container_left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 2rem;
  width: 55%;
}
.imgContainer {
  width: 33%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
}
.imgContainer img {
  /* width: 100%; */
  height: 38rem;
}
@media screen and (min-width: 1400px) {
  .imgContainer img {
    /* width: 100%; */
    height: 43rem;
    position: absolute;
    top: 1.9%;
    z-index: 0;
  }
  .main_container_left {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 2rem;
    width: 95%;
  }
}

@media screen and (max-width: 1400px) {
  .main_container {
    width: 1330px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .main_container_left {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 2rem;
    padding-left: 5rem;
    width: 70%;
  }
  .imgContainer {
    width: 40%;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
  }
  .imgContainer img {
    /* width: 100%; */
    height: 40rem;
  }
}
@media screen and (max-width: 1300px) {
  .main_container {
    width: 100vw;
    margin-top: 4rem;
    /* padding-left: 3rem; */
  }
  .container_sixth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-top: 5px;
    width: 95%;
    margin-top: 8rem;
  }
}

.main_container_left h1 {
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
}
.main_container_left p {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  /* margin-top: 1rem; */
}
.btn_container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin-top: 2rem;
  gap: 1.5rem;
}
.btn_ctn_1 {
  display: flex;
  text-decoration: none !important;
  color: #fff;
  height: 3.8rem;
  width: 12rem;
  font-weight: 400;
  line-height: 1.5;
  border: 3px solid;
  border-image: linear-gradient(45deg, #d02642, #f78245) 1;
  background-color: #001841;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.btn_ctn_1:hover {
  background: linear-gradient(90deg, #f98747, #cf2442);
  border: 3px solid;
  border-image: linear-gradient(90deg, #f98747, #cf2442) 1;
  color: #fff;
}
.btn_ctn_2 {
  display: flex;
  text-decoration: none !important;
  color: #fff;
  height: 3.8rem;
  width: 15rem;
  font-weight: 400;
  line-height: 1.5;
  border: 3px solid;
  border-image: white;
  background-color: #001841;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.btn_ctn_2:hover {
  background: white;
  border: 3px solid white;
  border-image: white;
  color: black;
}
.container_second {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1400px;
  height: 100%;
  /* padding-left: 3rem;
  padding-right: 1rem; */
}
.papaDiv_scnd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.papaDiv_Third {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.container_second_left_inner {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  flex-wrap: nowrap;
}
.container_second_left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 50%;

  padding: 3rem;
  height: 100%;
}
.container_second_left h1 {
  font-size: 3.3rem;
  /* font-weight: 500; */
  color: white;
  font-weight: 900;
  line-height: 1.4;
  width: 100%;
}
.container_second_right {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 50%;
  height: 100%;
  padding: 3rem;
}
.container_second_right h1 {
  font-size: 3.3rem;
  font-weight: 500;
  color: #cf2442;
  font-weight: 900;
  line-height: 1.4;
}
.container_second_right p {
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
  /* margin-top: 1rem; */
}
.middle {
  border-bottom-width: 100%;
  border-bottom-color: #cf2442;
  border-bottom-style: solid;

  margin-top: 2rem;
}
.second_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin-top: 2rem;
  gap: 1.5rem;
}

.scnd_btn {
  background: #cf2442;
  border-radius: 38px;
  color: white;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  height: 5rem;
  width: 17rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  border: 0;
  outline: 0;
}
.scnd_btn:hover {
  box-shadow: 0px 0px 30px 0px #cf2442;
}
.middleSecond {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #cf2442;
}
.scnd_btn_2 {
  background: white;
  border-radius: 33px;
  color: black;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  height: 5rem;
  width: 12rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  border: 0;
  outline: 0;
}
.scnd_btn_2:hover {
  box-shadow: 0px 0px 30px 0px #fff;
}
.middleSecond_1 {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
}
.container_third {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container_third > div {
  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
}
.container_third h1 {
  font-size: 3.4rem;
  font-weight: 500;
  color: white;
  font-weight: 900;
  line-height: 1.4;
}
.span_1 {
  color: white;
  font-size: 1rem;
  /* margin-left: 1rem; */
  width: 1110px;
}
.contianer_card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 1330px; */
  gap: 2rem;
}
.cardOneContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  row-gap: 1rem;
  width: 26%;
  height: 30rem;
  border-radius: 22px;
  background-color: #001232;
  border: 1px solid #011b51;
}
.contianer_card_three {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
}
.cardTwoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  row-gap: 1rem;
  width: 25%;
  height: 40rem;
  border-radius: 22px;
  background-color: #001232;
  border: 5px solid #cf2442;
  margin-top: 5rem;
}
.cardThreeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  row-gap: 1rem;
  width: 28%;
  height: 40rem;
  border-radius: 22px;
  background-color: #001232;
  border: 1px solid #011b51;
  margin-top: 2rem;
}
.cardThreeContainer h1 {
  font-size: 2rem;
  font-weight: 400;
  color: white;
  font-weight: 400;
  line-height: 1.4;
}
.cardThreeContainer p {
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  width: 85%;
}
.cardTwoContainer p {
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  width: 90%;
}
.cardOneContainer h1 {
  font-size: 2rem;
  font-weight: 400;
  color: white;
  font-weight: 400;
  line-height: 1.4;
}
.cardOneContainer p {
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  width: 90%;
}
.imagCardContainer {
  width: 8rem;
  height: 8rem;
}
.imagCardTwoContainer {
  height: 1.4rem;
}
.imagCardTwoContainer img {
  width: 100%;
  height: 100%;
}
.imagCardContainer img {
  width: 100%;
  height: 100%;
}
.cardTwoBtnBTN {
  border-radius: 30px;
  background: linear-gradient(90deg, #f98747, #cf2442);
  color: white;
  font-size: 1.2rem;
  width: 12rem;
  font-weight: 400;
  padding: 0.5rem;
  outline: none;
  border: none;
}
.cardOneBtnBTN {
  border-radius: 30px;
  background-color: #00194a;
  border: 1px solid #011b51;
  color: #0048a9;
  font-size: 1.2rem;
  width: 14rem;
  font-weight: 400;
  padding: 1rem;
}
.learnMore h1 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #1142a1;
  font-weight: 400;
  line-height: 1.4;
  margin-left: 1rem;
}

.container_fourth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  padding: 5rem;
  width: 1330px;
}
.container_fourth h1 {
  font-size: 3.4rem;
  font-weight: 500;
  color: #cf2442;
  font-weight: 900;
  line-height: 1.4;
}
.span_3 {
  border: 2px solid #cf2442;
  width: 100%;
  padding: 1.3rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
}
.scnd_btn__1 {
  background: #cf2442;
  border-radius: 38px;
  color: white;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  height: 5rem;
  width: 30rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  border: 0;
  outline: 0;
}
.scnd_btn__1:hover {
  box-shadow: 0px 0px 30px 0px #cf2442;
}

.container_fourth_inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5rem;
  gap: 2rem;
  padding: 0rem;
}
.container_fourth_inner_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.container_fourth_inner_left h1 {
  font-size: 2.3rem;
  font-weight: 500;
  color: white;
  width: 100%;
  line-height: 1.4;
  /* margin-left: -2rem; */
}
.container_fourth_inner_left li {
  font-size: 1rem;
  font-weight: 400;
  color: white;
  width: 100%;
  line-height: 1.3;
  margin-top: 8px;
  list-style: decimal;
  letter-spacing: 0.5px;
  margin-left: 1rem;
}
.container_fourth_inner_left li a {
  color: #cf2442;
  text-decoration: none;
  cursor: pointer;
}
.container_fourth_inner_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.container_fourth_inner_right p {
  font-size: 1rem;
  font-weight: 100;
  color: white;
  width: 90%;
  line-height: 1.3;
  margin-top: 8px;
  letter-spacing: 0.5px;
}
.container_fifth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-top: 5px;
  width: 100%;
}
.inner_fifth {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  flex-wrap: nowrap;
  column-gap: 10rem;
}
.inner_fifth_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
}
.inner_fifth_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
}
.inner_fifth_left img {
  width: 25rem;
  height: 100%;
}

.inner_fifth_right img {
  width: 25rem;
  height: 100%;
}
.container_fifth_box {
  background-color: white;
  border-radius: 22px;
  width: 98%;
}
.container_sixth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-top: 5px;
  width: 1330px;
  margin-top: 8rem;
}
.container_sixth_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  /* margin-top: 5px; */
  width: 100%;
}
.container_sixth_inner h1 {
  font-size: 3.3rem;
  font-weight: 900;
  color: #cf2442;
  line-height: 1.4;
}
.sixthHR {
  background-color: #cf2442;
  width: 12%;
  height: 0.1rem;
}
.container_sixth_inner p {
  font-size: 1.3rem;
  font-weight: 400;
  color: white;
  line-height: 1.4;
  text-align: center;
  /* margin-top: 0.2rem; */
}
.sixth_para {
  width: 50%;
  margin-top: 5rem;
}
.container_seventh {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  justify-items: start;
  width: 1330px;
  padding-left: 8.2rem;
}
.container_seventh_inner {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  justify-items: start;
}
.container_seventh_inner h1 {
  font-size: 3rem;
  color: #db4744;
}
.container_seventh_inner p {
  color: white;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  width: 75%;
  margin-top: 0.2rem;
}
.container_eighth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-top: 5px;
  /* width: 100%; */
  margin-top: 8rem;
  background-color: #f5f5fb;
}
.container_eighth_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  /* margin-top: 5px; */

  padding-top: 5rem;
  padding-bottom: 5rem;
}
.container_eighth_inner h1 {
  font-size: 3.3rem;
  font-weight: 900;
  color: #0b327c;
  line-height: 1.4;
}

.cardFour_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-top: 5px;
  width: 100%;
  gap: 2rem;
}
.inner_card_four {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  justify-items: start;
  margin-top: 5px;
  width: 350px;
  margin-top: 4rem;
  height: 38rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
}
.inner_div_left {
  width: 100%;
}

.inner_card_four:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.inner_card_four_img {
  height: 16.5rem;
  width: 100%;
  position: relative;
}
.inner_card_four_img img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.inner_card_four p {
  color: #929292;
  font-size: 1.2rem;
}
.bottom_card_four {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  justify-items: start;
  padding: 1rem;
}
.bottom_card_four h1 {
  color: black;
  font-size: 2rem;
  font-weight: 100;
}
.card_four_top_heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 8.8rem;
  height: 2.2rem;
  background-color: white;
  border-radius: 20px;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
@media screen and (max-width: 1120px) {
  .imgContainer {
    width: 40%;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
  }
  .imgContainer img {
    /* width: 100%; */
    height: 40rem;
  }
  .span_1 {
    color: white;
    font-size: 1rem;
    /* margin-left: 1rem; */
    width: 100%;
  }
  .container_second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .container_eighth_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    /* margin-top: 5px; */
    width: 95%;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .inner_card_four {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    justify-items: start;
    margin-top: 5px;
    width: 50%;
    margin-top: 4rem;
    height: 38rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  .cardFour_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-top: 5px;
    width: 100%;
    gap: 2rem;
  }
  .container_second_left_inner {
    flex-wrap: wrap;
  }
  .container_second_left {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;

    /* padding: 5rem; */
    height: 100%;
    margin-left: 0rem;
  }
  .container_second_right {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    height: 100%;
    /* padding: 3rem; */
  }
  .cardOneContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    row-gap: 1rem;
    width: 80%;
    height: 30rem;
    border-radius: 22px;
    background-color: #001232;
    border: 1px solid #011b51;
  }
  .container_fourth_inner {
    display: flex;
    justify-content: start;
    width: 100%;
    margin-top: 5rem;
    gap: 2rem;
    /* padding: 2rem; */
    flex-direction: column;
  }
  .container_fourth_inner_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0rem;
  }

  .container_fourth_inner_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: 0rem;
  }
  .container_fourth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    padding: 0rem;
    margin-top: 5rem;
    padding: 1rem;
    width: 100%;
  }
  .inner_fifth {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    flex-wrap: wrap;
  }
  .cardTwoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    row-gap: 1rem;
    width: 80%;
    height: 40rem;
    border-radius: 22px;
    background-color: #001232;
    border: 5px solid #cf2442;
    margin-top: 5rem;
  }
  .description {
    text-align: center;
  }
  .container_sixth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-top: 5px;
    width: 95%;
    margin-top: 8rem;
    padding: 1rem;
  }
  .container_seventh {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: 100%;
    padding: 0rem;
  }
  .cardThreeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    row-gap: 1rem;
    width: 80%;
    height: 45rem;
    border-radius: 22px;
    background-color: #001232;
    border: 1px solid #011b51;
    margin-top: 2rem;
  }
  .contianer_card_three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
  }
  .container_seventh_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding: 1rem;
  }
  .container_seventh_inner h1 {
    font-size: 2rem;
    font-weight: 100;
    text-align: start;
  }
  .container_seventh_inner p {
    width: 100%;
  }
  .contianer_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
  }
}
@media screen and (max-width: 1040px) {
  .imgContainer {
    width: 100%;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
  }
  .imgContainer img {
    /* width: 100%; */
    height: 40rem;
  }
  .main_container {
    width: 100vw;
    flex-direction: column;
  }
  .main_container_left {
    width: 70%;
    padding: 1rem;
  }
  .contianer_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
  }
  .span_3 {
    border: 2px solid #cf2442;
    width: 100%;
    padding: 1.3rem;
    font-weight: 500;
    color: white;
    word-break: break-all;
  }
  .span_3 span {
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
  }
  .sixth_para {
    width: 80%;
    margin-top: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .main_container {
    width: 100vw;
    flex-direction: column;
  }
  .main_container_left {
    width: 100%;
  }
  .second_btn_container {
     display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin-top: 2rem;
  gap: .5rem;
  }
  .scnd_btn {
  background: #cf2442;
  border-radius: 38px;
  color: white;
  display: flex;
  justify-content: center;
  gap: .5rem;
  height: 3rem;
  width: 9rem;
  align-items: center;
   padding: 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  outline: 0;
}
.scnd_btn_2 {
  background: white;
  border-radius: 33px;
  color: black;
  display: flex;
  justify-content: center;
  gap: .5rem;
  height: 3rem;
  width: 8rem;
  align-items: center;
  padding: 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  outline: 0;
}
  .btn_container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin-top: 2rem;
    gap: 0.5rem;
  }
  .btn_ctn_1{
     display: flex;
  text-decoration: none !important;
  color: #fff;
  height: 3.8rem;
  width: 8rem;
  padding: 0.5rem;
  font-weight: 400;
  line-height: 1.5;
  border: 3px solid;
  border-image: linear-gradient(45deg, #d02642, #f78245) 1;
  background-color: #001841;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  }
  .btn_ctn_2{
    display: flex;
  text-decoration: none !important;
  color: #fff;
  height: 3.8rem;
  width: 10rem;
  font-weight: 400;
  line-height: 1.5;
  border: 3px solid;
  border-image: white;
  background-color: #001841;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 0.5re,;
  }
  .imgContainer {
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
  }
  .imgContainer img {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .container_second_left {
    padding: 1rem;
    margin-top: 1rem;
  }
  .inner_card_four {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    justify-items: start;
    margin-top: 5px;
    width: 95%;
    margin-top: 4rem;
    height: 38rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  .container_eighth_inner h1 {
    font-size: 2rem;
  }
  .imgContainer {
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
  }
  .container_second_left {
    width: 100%;
  }
  .container_second_right {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
  }
  .imgContainer img {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
  }
  .main_container_left h1 {
    font-size: 2.2rem;
  }
  .main_container_left p {
    font-size: 1.2rem;
  }
  .container_second_left h1 {
    font-size: 2.2rem;
  }
  .container_second_left p {
    font-size: 1.2rem;
  }
  .container_second_right h1 {
    font-size: 2.2rem;
  }
  .container_second_right p {
    font-size: 1.2rem;
  }
  .papaDiv_scnd {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .container_second {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .cardOneContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    row-gap: 1rem;
    width: 95%;
    height: 30rem;
    border-radius: 22px;
    background-color: #001232;
    border: 1px solid #011b51;
  }
  .cardTwoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    row-gap: 1rem;
    width: 95%;
    height: 40rem;
    border-radius: 22px;
    background-color: #001232;
    border: 5px solid #cf2442;
    margin-top: 5rem;
  }
  .container_third h1 {
    font-size: 2.3rem;
    font-weight: 500;
    color: white;
    font-weight: 900;
    line-height: 1.4;
  }
  .cardOneContainer h1 {
    font-size: 1.8rem;
    font-weight: 400;
    color: white;
    font-weight: 400;
    line-height: 1.4;
  }
  .container_fourth h1 {
    font-size: 2rem;
    font-weight: 500;
    color: #cf2442;
    font-weight: 600;
    line-height: 1.4;
  }
  .scnd_btn__1 {
    width: 100%;
    height: 4rem;
    padding: 2rem;
  }
  .container_sixth_inner h1 {
    font-size: 2.3rem;
    font-weight: 900;
    color: #cf2442;
    line-height: 1.4;
  }
}

@media screen and (max-width: 508px) {
  /* .inner_fifth_left {
    width: 15rem;
    height: 6rem;
  } */
  .inner_fifth_left img {
    width: 100%;
    height: 100%;
  }
  /* .inner_fifth_right {
    width: 15rem;
    height: 6rem;
  } */
  .inner_fifth_right img {
    width: 100%;
    height: 100%;
  }
  .container_sixth_inner {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .sixthHR {
    display: none;
  }
  .container_sixth_inner p {
    font-size: 1.3rem;
    font-weight: 400;
    color: white;
    line-height: 1.4;
    text-align: start;
    /* margin-top: 0.2rem; */
  }

  
  .inner_fifth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }
  .sixth_para {
    width: 95%;
    margin-top: 5rem;
    text-align: start;
  }
}

@media screen and (max-width: 468px) {
  .scnd_btn__1 {
    width: 100%;
    font-size: 1rem;
    background: #cf2442;
    border-radius: 38px;
    color: white;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    height: 4rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    border: 0;
    outline: 0;
    padding: 0.5rem;
  }
  .cardThreeContainer {
    height: 50rem;
  }
  .cardThreeContainer h1 {
    font-size: 1.6rem;
    font-weight: 400;
    color: white;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;
  }
  .container_fourth_inner_left h1 {
    font-size: 1.8rem;
    color: white;
    font-weight: 600;
    line-height: 1.4;
    text-align: start;
  }
}
