.BuyMettaBackGround {
  background-image: url(../../assets/Staking-HeroBG.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 100vh; */
}
.btnCARD {
  transition: 900ms;
}
.btnCARD:hover {
  transform: scale(1.1);
}
.btnCARD:active {
  background: #021335;
}

.circleBTN {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #021335;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: 10px solid #010817;
  margin: 10px;
  transition: 900ms;
}

/* @media (max-height: 915px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 120vh;
  }
}
@media (max-height: 1080px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 130vh;
  }
}
@media (max-height: 805px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 135vh;
  }
}
@media (max-height: 785px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 130vh;
  }
}
@media (max-height: 750px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 140vh;
  }
}
@media (max-height: 710px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 145vh;
  }
}
@media (max-height: 650px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 215vh;
  }
}
@media (max-height: 550px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 265vh;
  }
}
@media (max-height: 450px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 470vh;
  }
}
@media (max-height: 350px) {
  .BuyMettaBackGround {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 550vh;
  }
} */
