.button {
  background-color: white;
  transition: 900ms;
}
.button:hover {
  background: rgb(228, 98, 80);
  background: linear-gradient(
    90deg,
    rgba(228, 98, 80, 1) 0%,
    rgba(255, 67, 35, 1) 100%
  );
  transition: 900ms;
  transform: scale(1.1);
}
